<template>
  <div class="DomainList" >

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>


    <!-- DELETE DOMAIN -->
    <b-modal ref="deleteDomainModal" size="lg" hide-footer>
      <template v-slot:modal-title>
        <strong> Supprimer ce domaine </strong>
      </template>
      <div>
        <p>
          Attention, si vous supprimez ce domaine, il ne sera plus possible de l'utiliser et les pages liées n'utiliseront plus sa connexion.
        </p>
        <b-button
          pill block variant="outline-danger" class="mt-2"
          @click='deleteDomain'
          :disabled="deleteDomainInProcess">
            Je confirme la suppression du domaine
        </b-button>
      </div>
    </b-modal>

    <b-modal ref="DomainDeletedModal" id="DomainDeletedModal" size="lg" hide-footer title="Domaine supprimé">
      <div class="text-center">
        <p><strong>Votre domaine a bien été supprimé</strong></p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('DomainDeletedModal')">Fermer</b-button>
      </div>
    </b-modal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container-fluid">
        <h1 class="form_div_title text-center">Paramétrage des domaines</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="align-self-center col-12 col-sm-6">
                <strong> Liste des domaines de Decidcrawl</strong>
              </div>
              <div class="align-self-center col-12 col-sm-6 text-right">
                <b-button variant="outline-success" pill @click="$router.push('/domains/creation')">
                  <v-icon name="plus"/> Ajouter un domaine
                </b-button>
              </div>
            </div>
            <b-table
              class="align-middle mt-2"
              headVariant="light"
              :fields="domainsRenderFields"
              :items="domains"
              >

              <template v-slot:cell(actions)="data">
                <b-button
                  variant="outline-success" class="ml-1" size="sm"
                  v-b-popover.hover.top="'Tester le login'"
                  @click="testDomain(data.item.id)">
                  <v-icon name="sync"/>
                </b-button>
                <b-button
                  :href="data.item.url_login" target="_blank"
                  variant="outline-secondary" class="ml-1" size="sm"
                  v-b-popover.hover.top="'Aller à la page de login'">
                  <v-icon name="external-link-alt"/>
                </b-button>
                <b-button
                  variant="outline-primary" class="ml-1" size="sm"
                  @click="$router.push('/domains/' + data.item.id)">
                  <v-icon name="edit"/>
                </b-button>
                <b-button
                  variant="outline-danger" class="ml-1" size="sm"
                  @click='showDeleteDomainModal(data.item.id)'>
                  <v-icon name="trash-alt"/>
                </b-button>
              </template>
            </b-table>

          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APIDomain } from '@/api/APIDomain'
import formTitleComponent from '@/components/formTitleComponent'
import renderMixins from '@/mixins/renderMixins'

const apiDomain = new APIDomain()

export default {
  name: 'DomainList',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    renderMixins,
  ],
  data: function () {
    return {
      domains: [],
      domainsRenderFields: [
        { key: "name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "domain_name", label: "Nom de domaine", tdClass: 'ui-helper-center', sortable: true},
        { key: "login", label: "Identifiant", tdClass: 'ui-helper-center'},
        { key: "password", label: "Mot de passe", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'minWidthTd ui-helper-center'},
      ],
      isDomainsLoading: true,

      // ----- DELETE DOMAIN -----
      deleteDomainInProcess: false,
      deleteDomainId: '',

      isTestingLogin: false

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDomainsLoading || this.deleteDomainInProcess || this.isTestingLogin) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllDomains()
  },
  methods: {
    // ------------- GETTERS ------------------
    getAllDomains () {
      this.isDomainsLoading = true
      apiDomain.getAllDomains(this.token)
      .then((result) => {
        this.domains = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DomainList getAllDomains API Error : ' + String(error))
      })
      .finally(() => {
        this.isDomainsLoading = false
      })
    },


    // ----------- DELETE DOMAIN -----
    showDeleteDomainModal (domainId) {
      this.deleteDomainId = domainId
      this.$refs.deleteDomainModal.show()
    },

    deleteDomain () {
      this.deleteDomainInProcess = true
      apiDomain.deleteDomain(this.token, this.deleteDomainId)
      .then(() => {
        this.$refs.deleteDomainModal.hide()
        this.$refs.DomainDeletedModal.show()
        this.getAllDomains()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DomainList deleteDomain API Error : ' + String(error))
      })
      .finally(() => {
        this.deleteDomainInProcess = false
      })

    },

    testDomain (id) {
      this.isTestingLogin = true
      apiDomain.testDomain(this.token, id)
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'loginpage.png'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DomainList testDomain API Error : ' + String(error))
      })
      .finally(() => {
        this.isTestingLogin = false
      })

    },
  }
}
</script>
